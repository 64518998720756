<template>
  <svg xmlns="http://www.w3.org/2000/svg"
       fill="none"
       viewBox="0 0 24 24"
       stroke-width="1.5"
       stroke="currentColor"
  >
    <Motion tag="path"
            pathLength="1"
            :initial="{ strokeDashoffset: 1 }"
            :animate="draw(1)"
            :transition="{ duration: 0.5, delay: 0.25 }"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-dasharray="1"
            d="M6 18L18 6M6 6l12 12"
            class="invisible fill-transparent"
    />
  </svg>
</template>

<script setup lang="ts">
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { Motion } from '@motionone/vue/dist/motion-vue.ssr.js'

function draw(progress: number): { strokeDashoffset: number, visibility: string } {
  return {
    // This property makes the line "draw" in when animated
    strokeDashoffset: 1 - progress,

    // Each line will be hidden until it starts drawing
    // to fix a bug in Safari where the line can be
    // partially visible even when progress is at 0
    visibility: 'visible'
  }
}
</script>
